<template>
  <div class="flex flex-wrap w-full min-h-screen">
    <div class="grid grid-cols-2 w-full">
      <div class="flex flex-col items-center justify-center gap-2 w-full">
        <div class="flex justify-center" style="width: 40%">
          <img alt="logo" src="@/assets/images/sme_logo.png" />
        </div>
        <div class="flex flex-col gap-2" style="width: 60%">
          <h3 class="text-2xl text-center mt-4">Sign up</h3>
          <span class="text-center">Choose your path and let's get started!</span>
          <div class="flex flex-row items-center justify-center gap-2">
            <SelectButton v-model="consult" :options="options" optionLabel="label" />
          </div>
          <Button class="p-button border-0 facebook w-full" @click.prevent="$emit('facebook-sign-in')" label="Continue with Facebook" />
          <Button class="p-button border-0 google w-full" @click.prevent="$emit('google-sign-in')" label="Continue with Google" />
          <Divider/>
          <Button class="p-button p-button-link w-full" @click="$emit('sign-in')" label="Already have an account?" />
        </div>
      </div>
      <div class="flex flex-col items-center justify-center border-l border-black" >
        <img alt="sign-up" src="@/assets/images/signup-1.jpg" style="width: 80%" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import SelectButton from 'primevue/selectbutton';
import Divider from 'primevue/divider';
export default {
  components: {
    Divider,
    SelectButton,
    Button,
  },
  data() {
    return {
      consult: true,
      options: [
        { label: 'Find a Resource', value: false },
        { label: 'Provide a Service', value: true },
      ],
    };
  },
};
</script>

<style lang="css" scoped>
.facebook {
  background-color: #3b5998;
}

.facebook:hover {
  background-color: #294175;
}

.google {
  background-color: #dd4b39;
}

.google:hover {
  background-color: #c23321;
}

.shadow-4 {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
}
</style>
